.footer {
  width: 100%;
  background-color: rgb(5, 2, 0);
  color: white;
  font-family: 'Fraunces';
  font-weight: 250;
  padding: 50px 20px;
  margin-top: 50px;
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profile {
  margin-bottom: 20px;
}

.nav-profile-pic {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

.footer-intro {
  margin: 10px 0;
  color: white;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-links a {
  margin: 0 10px;
}

.ownership {
  margin: 20px 0;
  color: white;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-link {
  font-size: 18px;
  color: white !important;
  padding: 10px 20px;
  font-family: 'Fraunces';
  font-weight: 250;
}

.nav-link:hover {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-around;
  }

  .links {
    flex-direction: row;
  }

  .ownership {
    margin: 0;
    padding-left: 20px;
  }
}
