.page-header {
  text-align: center;
  padding-top: 50px;
  font-family: 'sp-blunt-regular';
  font-size: 2.5em;
  color: black;
}

.projects-page {
  padding: 20px;
  background-color: white;
}

.project-card {
  border: 10px solid black;
  transition: transform 0.3s ease;
  background-color: black;
  color: white;
}

.project-card:hover {
  transform: scale(1.05);
}

.pv-project-image,
.cc-project-image,
.pw-project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.bo-project-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 0 auto;
}

.cards-title {
  font-size: 1.2em;
  margin-top: 10px;
  color: #e9ecf0;
  font-family: 'sp-blunt-regular';
}

.cards-text {
  font-size: 1em;
  color: #c4c1c1;
  font-family: 'sp-blunt-regular';
}

.list-group-tech-stack {
  font-family: 'sp-blunt-regular';
  background-color: black;
  color: white;
  border: none;
}

.list-group-technologies {
  text-align: left;
  font-family: 'sp-blunt-regular';
  background-color: black;
  color: white;
  border: none;
}

@media (max-width: 1200px) {
  .cards-title {
    font-size: 1em;
  }

  .cards-text {
    font-size: 0.9em;
  }

  .list-group-technologies {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .projects-page {
    padding: 10px;
  }

  .page-header {
    font-size: 2em;
    padding-top: 20px;
  }

  .cards-title {
    font-size: 0.9em;
  }

  .cards-text {
    font-size: 0.8em;
  }

  .list-group-technologies {
    font-size: 0.8em;
  }

  .bo-project-image {
    width: 80px;
    height: 80px;
  }
}
