.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  background-image: url('/public/img/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  padding: 20px;
}

.text-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 0 20px;
}

.intro-header {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: white;
  font-family: 'sp-blunt-regular';
  font-weight: 250;
}

.intro-sub-header {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: white;
  font-family: 'sp-blunt-regular';
  font-weight: 250;
}

.text-card {
  background-color: rgba(0, 0, 0, 0.4) !important;
  color: white !important;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  font-family: 'sp-blunt-regular';
  border: 10px solid rgb(184, 102, 8);
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-pic {
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
  border: 10px solid rgb(184, 102, 8);
}

@media (max-width: 1200px) {
  .text-content {
    margin: 0 10px;
  }

  .intro-header {
    font-size: 2em;
  }

  .intro-sub-header {
    font-size: 1.3em;
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-content, .image-content {
    margin-bottom: 20px;
    align-items: center;
  }

  .text-content {
    text-align: center;
  }

  .portfolio-pic {
    width: 80%;
    max-width: 300px;
    margin: 0 auto;
  }

  .intro-header {
    font-size: 1.8em;
  }

  .intro-sub-header {
    font-size: 1.2em;
  }
}
