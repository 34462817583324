.skills-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
}

.skills-content {
  width: 80%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 10px;
}

.skills-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-family: 'sp-blunt-regular';
  font-weight: 250;
}

.skill-card {
  margin-bottom: 20px;
  border: none;
  transition: transform 0.3s ease;
}

.skill-card:hover {
  transform: scale(1.05);
}

.skill-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 0 auto;
}

.cards-title {
  font-size: 1.2em;
  margin-top: 10px;
  color: black;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-custom {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'sp-blunt-regular';
  font-weight: 250;
}

.btn-custom:hover {
  background-color: #333;
}

.back-to-top {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  font-family: 'sp-blunt-regular';
  font-weight: 250;
}

@media (max-width: 1200px) {
  .skills-title {
    font-size: 2em;
  }

  .cards-title {
    font-size: 1em;
  }

  .btn-custom {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

@media (max-width: 768px) {
  .skills-content {
    width: 100%;
    padding: 20px;
  }

  .skills-title {
    font-size: 1.8em;
  }

  .skill-image {
    width: 80px;
    height: 80px;
  }

  .cards-title {
    font-size: 0.9em;
  }

  .btn-custom {
    font-size: 0.8em;
    padding: 6px 12px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  .navigation-buttons button {
    margin-bottom: 10px;
  }
}
