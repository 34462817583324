.top-nav {
  width: 100%;
  height: 80px;
  background-color: black;
  padding: 10px 20px;
  color: white;
  font-family: "Fraunces";
  font-weight: 250;
  align-items: center;
  position: relative;
  z-index: 1000;
}

.navbar-brand {
  color: white;
  font-family: "Fraunces";
  font-weight: 250;
}

.navbar-brand:hover {
  color: rgb(143, 95, 6);
}

.navbar-toggler {
  border-color: white;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-collapse {
  justify-content: flex-end;
}

.nav-links .nav-link {
  color: white;
  margin-left: 20px;
}

.nav-links .nav-link:hover {
  color: rgb(143, 95, 6);
  text-decoration: underline;
}
