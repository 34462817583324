.about-me-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url('/public/img/office.png') no-repeat center center fixed;
  background-size: cover;
  padding: 20px;
  box-sizing: border-box;
}

.about-me-background {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  margin: 20px;
  box-sizing: border-box;
}

.about-me-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.about-me-text {
  font-size: 1em;
  flex: 2;
  padding: 20px;
  font-family: 'sp-blunt-regular';
  font-weight: 250;
  box-sizing: border-box;
}

.about-me-text h2 {
  margin-bottom: 20px;
  color: #333;
}

.about-me-text p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #555;
}

.about-me-image {
  flex: 1;
  text-align: center;
  margin: 20px 0;
}

.profile-pic {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .about-me-content {
    flex-direction: column;
    align-items: center;
  }

  .about-me-text {
    text-align: center;
  }

  .profile-pic {
    width: 200px;
    height: 200px;
  }
}
